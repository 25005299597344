.leaflet-container {
  height: 100%;
  pointer-events: auto;
}

.plane-icon {
  background: none;
  border: none;
  fill: white;
  color: white;
  font-size: 14px;
  z-index: 999 !important;
  filter: drop-shadow(2px 2px 0 rgb(0 0 0 / 0.3));
  position: relative;
  /*padding: 20px;     
  margin: -20px; 
  padding-right: 40px;
  padding-bottom: 40px;
  margin-right: -40px;
  margin-bottom: -40px;*/
  pointer-events: auto;
  cursor: pointer;
}

.plane-icon::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 300%;
  height: 300%;
  z-index: 99999;
  pointer-events: auto;
  /*background-color: green;*/
}

.plane-icon-active {
  color: #F7CE46 !important;
  fill: #F7CE46 !important;
}

.radius-circle {
  stroke: #EB4E3D !important;
}

.debug-label {
  color: white;
  font-size: 9px;
}

.route-line {
  stroke: #65C466;
}
