body {
  background-color: #F1F2F7;
}

.app {
}

.map {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.MuiGrid-item {
  margin: auto !important;
}

header {
  position: absolute;
  width: 100%;
  z-index: 1000;
  text-align: center;
  height: 60px;
  pointer-events: none;
  color: #F1F2F7;
  font-weight: 500;
  top: 10px;
}

header a {
  text-decoration: none;
  color: unset;
}

header a:vistied {
  color: unset;
}

header span {
  padding-top: 10px;
  pointer-events: none;
}

.aircraft-panel-container {
  
}

.aircraft-panel {
  position: absolute;
  left: 0;
  bottom: 0;
  /* padding: 10px; */
  z-index: 500;
  width: 100%;
  height: 100% !important;
  animation: showPanel .3s ease-out both;
  overflow-y: scroll;
  overflow-x: hidden;
  pointer-events: auto;
}

.aircraft-spacer {
  min-height: 72vh;
  pointer-events: none;
}

.aircrafts {
  opacity: 0.9;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 22px;
}

@media (min-width: 768px) {
  .aircraft-panel {
    margin-left: 20px;
    width: 360px;
    height: auto !important;
    max-height: 60vh;
    /*overflow-y: unset;*/
    /*overflow-x: unset;*/
  }

  .aircrafts {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .aircraft-spacer {
    min-height: unset;
    height: 0;
  }
}

.aircraft-state {
  padding: 20px;
}

.aircraft-info-title {
  text-transform: uppercase;
  font-size: 10px;
  opacity: 0.8;
  display: block;
  text-align: left;
  padding-bottom: 4px;
}

.aircraft-info-value {
  display: block;
  text-align: left;
  font-size: 15px;
}

@media screen and (max-width: 600px) {
  .aircraft-panel {
    width: 100%;
    height: 50%;
  }
}

.accent {
  color: #52AEDD;
  fill: #52AEDD;
}

.subtle {
  color: #AEAEB2 !important;
}

footer {
  position: fixed;
  bottom: 20px;
  text-align: center;
  width: 100%;
}

.aircrafts-header {

}

.konami {
  /*height: 18px;*/
}

.debug-panel {
  padding: 20px;
}

.debug-panel > label {
  padding-right: 20px;
}

.current-location-outer {
  margin-top: -11px !important;
  margin-left: -11px !important;
  height: 20px !important;
  width: 20px !important;
  border-color: white;
  border-style: solid;
  border-radius: 50%;
  border-width: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 22px;
}

.current-location {
  width: 100%;
  height: 100%;
  background-color: #52AEDD;
  border-radius: 50%;
}

.image-modal {
  max-width: 90% !important;
}

/*.image-modal button {
  color: #fff !important;
}*/

.hover-time {
  line-height: 40px !important;
}

.locate-button {
  color: black !important;
  width: 30px;
  height: 30px;
  font-size: 16px !important;
  border-radius: 6.67% !important;
  background-color: white !important;
  text-align: center;
}

.locate-button svg {
  margin-top: 2px;
}

.locate-spinner {
  margin-top: 7px;
}

.text-hover-underline:hover {
  text-decoration: underline;
}

.text-hover-underline-span > span:hover {
  text-decoration: underline;
}

.text-hover-bold:hover {
  font-weight: bold;
}

.react-datetime-picker input {
  color: white;
}

.react-datetime-picker select {
  color: white;
}

.react-datetime-picker svg {
  stroke:  white;
}
